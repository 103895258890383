import { CompanyShort } from '@mgk-eld/utils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  InputBase,
  Popper,
} from '@mui/material';
import Autocomplete, {
  AutocompleteCloseReason,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { useCallback, useState } from 'react';

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

export const StyledPopper = styled(Popper)(({ theme }) => ({
  boxShadow: '0px 8px 16px rgba(67, 66, 66, 0.15)',
  borderRadius: '0px 0px 8px 8px',
  width: 284,
  height: 252,
  zIndex: theme.zIndex.modal,
  fontSize: 14,
  backgroundColor: '#fff',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: '16px 12px 8px 12px',
  width: '100%',
  '& input': {
    borderRadius: 8,
    backgroundColor: '#F8F8F8',
    padding: '10px 12px',
    border: `1px solid #E5EAED`,
    fontSize: '14px',
  },
}));

const Button = styled(ButtonBase)({
  fontSize: '14px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  '& span': {
    width: '100%',
  },
  '& svg': {
    width: 16,
    height: 16,
  },
});

export const CompanySelect: React.FC<{
  selected?: CompanyShort;
  companiesList: CompanyShort[];
  disabled?: boolean;
  WrapperComponent?: any;
  handleChange: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: CompanyShort | null
  ) => void;
}> = ({
  selected,
  handleChange,
  disabled,
  WrapperComponent,
  companiesList,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState<CompanyShort | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'company-label' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!disabled) setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
    setSearchValue(null);
  }, [anchorEl]);
  return (
    <>
      <Box
        sx={{
          marginTop: 0.5,
          pl: 2,
          minWidth: 100,
          maxWidth: 250,
          fontSize: 14,
          fontWeight: 500,
        }}
        onClick={handleClick}
      >
        {WrapperComponent ? (
          <WrapperComponent />
        ) : (
          <Button
            sx={{
              cursor: disabled ? 'not-allowed' : 'pointer',
            }}
            disableRipple
            aria-describedby={id}
            //   onClick={handleClick}
          >
            {selected?.name || ''}
            {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Button>
        )}
      </Box>
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              disabled={disabled}
              open
              onClose={(
                event: React.ChangeEvent<object>,
                reason: AutocompleteCloseReason
              ) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              value={searchValue}
              onChange={(event, val) => {
                handleChange(event, val);
                handleClose();
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText="No options"
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={`${props.key}-${option.id}`}
                  style={{
                    backgroundColor:
                      option?.id === selected?.id ? '#E6EFF7' : '',
                  }}
                >
                  <Box
                    sx={{
                      padding: '0 8px',
                      flexGrow: 1,
                      '& span': {
                        color: '#586069',
                      },
                    }}
                  >
                    {option?.name}
                  </Box>
                </li>
              )}
              options={companiesList}
              getOptionLabel={(option) => {
                return option.name || '';
              }}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder="Search"
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};
