import { HashRouter } from 'react-router-dom';
import * as ReactDOM from 'react-dom/client';
import App from './app/App';
import * as Sentry from '@sentry/react';
import { isStage } from '@mgk-eld/utils';
import { MuiXLicense } from '@mgk-eld/core';

if (!isStage()) {
  Sentry.init({
    dsn: 'https://bc529af1665c3ca3613768222ad2fc21@o4507215439003648.ingest.us.sentry.io/4507220261601280',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  console.log('Sentry enabled!');
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <HashRouter>
    <MuiXLicense />
    <App />
  </HashRouter>
);
