import { blue, grey } from '@mgk-eld/utils';

import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
  IconButton,
  InputAdornment,
  Paper,
  SxProps,
  TextFieldProps,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { CalendarIcon } from '@mgk-eld/assets';
import dayjs, { Dayjs } from 'dayjs';

export const CalendarCustomPaper = styled(Paper)`
  border: 1px solid ${grey[100]};
  border-radius: 8px;
  background: ${grey[50]};
  height: 40px;
`;

export const popperSx: SxProps = {
  '& .MuiPaper-root': {
    filter: 'drop-shadow(0px 8px 16px rgba(67, 66, 66, 0.15))',
    borderRadius: '8px',
  },
  '& .MuiDateCalendar-root': {
    borderRadius: '8px',
    '& .MuiPickersCalendarHeader-root': {
      backgroundColor: grey[1000],
      marginTop: 0,
      height: '40px',
      minHeight: '40px',
      '& *': {
        color: 'white',
      },
    },
    '& .MuiDayCalendar-header *': {
      fontSize: '11px',
      color: grey[300],
    },
    '& .MuiDayCalendar-monthContainer *': {
      fontSize: '14px',
      color: grey[1000],
      '& .Mui-selected': {
        color: 'white',
        background: blue[500],
        ':hover': {
          background: `${blue[250]} !important`,
        },
      },
      '& .MuiPickersDay-today': {
        border: `1px solid ${blue[500]}`,
      },
      '& .MuiPickersDay-root': {
        borderRadius: '8px',
      },
      '& .MuiPickersDay-root:hover': {
        background: blue[100],
      },
      '& .MuiPickersDay-root.Mui-disabled': {
        color: grey[300],
      },
    },
    'div.MuiPickersYear-root *': {
      fontSize: '14px',
      borderRadius: '8px',
    },
    'button:hover': {
      background: blue[100],
    },
    'button.Mui-selected': {
      color: 'white',
      background: blue[500],
    },
    'button.MuiIconButton-root:hover': {
      background: 'none',
    },
  },
  'li.MuiMenuItem-root': {
    borderRadius: '8px',
    ':hover': {
      background: blue[100],
    },
  },
  'li.Mui-selected': {
    ':hover': {
      background: blue[250],
    },
    color: 'white',
    background: blue[500],
  },
};

type Props = {
  date: Dayjs | null;
  disabled?: boolean;
  handleChange: (value: null | string) => void;
  additionalInputProps?: TextFieldProps;
  paperStyles?: any;
  enableClear?: boolean;
  fullWidth?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  disableFuture?: boolean;
  tz?: string;
  format?: string;
  setAnchorEl?: (val: any) => void;
};

export const YYYY_MM_DD_format = 'YYYY-MM-DD';

export const Calendar: React.FC<SxProps & Props> = ({
  date,
  handleChange,
  additionalInputProps,
  paperStyles,
  disabled = false,
  enableClear = false,
  fullWidth = false,
  disableFuture = true,
  tz,
  minDate,
  format = YYYY_MM_DD_format,
  maxDate = disableFuture ? dayjs() : undefined,
  setAnchorEl,
  ...sxProps
}) => {
  const isDateValid = Boolean(date?.isValid());
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        value={date}
        timezone={tz}
        disableFuture={disableFuture}
        maxDate={maxDate}
        minDate={minDate}
        disabled={disabled}
        onChange={(newValue) => {
          if (newValue) {
            handleChange(newValue.format(format));
          }
        }}
        onClose={() => setAnchorEl && setAnchorEl(null)}
        slots={{
          openPickerIcon: CalendarIcon,
          inputAdornment: (props) => {
            return (
              <>
                {enableClear && (
                  <InputAdornment
                    position="end"
                    onClick={() => handleChange(null)}
                  >
                    <IconButton sx={{ m: 0 }}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                )}
                {props.children}
              </>
            );
          },
        }}
        slotProps={{
          popper: {
            sx: popperSx,
            onFocus: (e) => setAnchorEl && setAnchorEl(e.currentTarget),
          },
          textField: {
            fullWidth,
            disabled: true,
            sx: {
              '& fieldset': { border: 'none' },
              '& div': { height: paperStyles?.height ?? '40px' },
              border: `1px solid ${grey[100]}`,
              borderRadius: '8px',
              background: grey[50],
              height: paperStyles?.height ?? '40px',
              ...sxProps,
              ...paperStyles,
            },
            // @ts-expect-error err
            inputProps: (value) => ({
              value: isDateValid ? value : '',
              'aria-label': 'date',
            }),
            ...additionalInputProps,
          },
        }}
      />
    </LocalizationProvider>
  );
};
