import { grey } from '@mgk-eld/utils';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { getSelectedCompanyId } from '../../../redux/companies/selectors';
import logsApi from '../../../services/logs';
import { LogChart } from './Chart';
import { ShiftType } from './Chart/Shift';
import { CycleData, Totals } from './types';
import { LogDetailRows } from './utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import dayjs, { Dayjs } from 'dayjs';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { YYYY_MM_DD_format } from '@mgk-eld/core';

export const extendedModes = [
  'INTERMEDIATE',
  'ENGINE_POWER_UP',
  'ENGINE_SHUT_DOWN',
  'LOGIN',
  'LOGOUT',
  'CERTIFICATION',
];

const LogEvents: React.FC<{
  logDetails: LogDetailRows;
  date: Dayjs;
  pastAndFutureRowIds: number[];
  totalDuration: number;
  totals?: Totals;
  violation?: { time: string };
  setHighlightedRowId: Dispatch<SetStateAction<number>>;
  highlightedRowId: number;
  time?: string;
}> = ({
  logDetails,
  time,
  totals,
  date,
  pastAndFutureRowIds,
  totalDuration,
  setHighlightedRowId,
  highlightedRowId,
}) => {
  const { state } = useLocation();
  const [cycleData, setCycleData] = useState<CycleData>();
  const selectedCompany = useAppSelector(getSelectedCompanyId);

  const getCycleData = useCallback(async () => {
    if (!selectedCompany) return;
    const cycle = await logsApi.getCycle({
      company_id: selectedCompany,
      driver_id: state.driver_id,
      date: date.format(YYYY_MM_DD_format),
    });
    setCycleData(cycle.data);
  }, [selectedCompany, date, state.driver_id]);

  useEffect(() => {
    getCycleData();
  }, [getCycleData]);

  useEffect(() => {
    logDetails.forEach((logDetail, index) => {
      const startDate = dayjs(logDetail.datetime);
      if (!startDate.isSame(date, 'days')) {
        if (!pastAndFutureRowIds.includes(index)) {
          pastAndFutureRowIds.push(index);
        }
      }
    });
  }, [logDetails, date, pastAndFutureRowIds]);

  const getShifts = () => {
    const shifts: Array<ShiftType> = [];
    if (time) {
      shifts.push({
        content: '14',
        date: new Date(0, 0, 0, ...time.split(':').map((t) => parseInt(t))),
      });
    }
    if (cycleData?.cycle_end) {
      shifts.push({
        content: String(cycleData.cycle),
        date: new Date(
          0,
          0,
          0,
          ...cycleData.cycle_end.split(':').map((t) => parseInt(t))
        ),
      });
    }
    return shifts;
  };

  return (
    <Grid item>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight={500} color={grey[600]}>
            Log events
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            sx={{ backgroundColor: '#F8F8F8' }}
            alignItems="center"
            pt="20px"
            pb="10px"
          >
            <Grid item xs={12}>
              <LogChart
                rows={logDetails}
                totals={totals}
                date={date.format('MM/DD/YYYY')}
                totalDuration={totalDuration}
                shifts={getShifts()}
                highlightedRowId={highlightedRowId}
                sethighlightedRowId={setHighlightedRowId}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default LogEvents;
