import { Button, Typography, Box, ButtonGroup } from '@mui/material';
import React, { Component, ErrorInfo } from 'react';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Error:', error);
    console.error('Error Info:', errorInfo);
  }

  reloadPage = () => {
    window.location.reload();
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <Typography variant="h4" gutterBottom>
            Oops! Something went wrong.
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            We apologize for the inconvenience. Please try reloading the page or
            go back to the home page.
          </Typography>
          <ButtonGroup>
            <Button
              variant="contained"
              color="primary"
              onClick={this.reloadPage}
            >
              Reload Page
            </Button>
            <Button
              variant="contained"
              sx={{ ml: 1 }}
              color="secondary"
              href="/"
            >
              Back to Home
            </Button>
          </ButtonGroup>
        </Box>
      );
    }

    return children;
  }
}
