import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface InputState {
  query: string;
  selectedRect: number;
  selectedRows: Array<number>;
  date?: string | null;
}

const initialState: InputState = {
  query: '',
  selectedRect: -1,
  selectedRows: [],
};

export const simpleSlice = createSlice({
  name: 'simple',
  initialState,
  reducers: {
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    setDate: (state, action: PayloadAction<string | null>) => {
      state.date = action.payload;
      sessionStorage.setItem('date', action.payload ?? '');
    },
    setSelectedRect: (state, action: PayloadAction<number>) => {
      state.selectedRect = action.payload;
    },
    setSelectedRows: (state, action: PayloadAction<Array<number>>) => {
      state.selectedRows = action.payload;
    },
  },
});

export const { setQuery, setDate, setSelectedRect, setSelectedRows } =
  simpleSlice.actions;

export default simpleSlice.reducer;
