import { Box, Typography } from '@mui/material';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

export const Loader: React.FC<{
  page?: boolean;
  progressProps?: CircularProgressProps;
  message?: string;
}> = ({ page = false, message, ...progressProps }) => {
  return page ? (
    <CircularProgress
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      {...progressProps}
    />
  ) : (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h4">{message}</Typography>
      <CircularProgress color="primary" />
    </Box>
  );
};
