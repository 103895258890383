import styled from '@emotion/styled';
import { Input } from '@mgk-eld/core';
import { Tooltip } from '@mui/material';
import React, { useCallback, useState } from 'react';

export const EditContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EditInput: React.FC<{
  value?: string;
  validations?: any;
  setValue: (input: string) => any;
  onValidationChange: (isValid: boolean) => void;
}> = ({ value, validations, setValue, onValidationChange }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleBlur = useCallback(() => {
    // setIsEditing(false);
  }, []);

  const stopPropagation = useCallback((e: any) => {
    e.stopPropagation();
  }, []);

  const handleSubmit = useCallback(
    (val: string) => {
      setIsEditing(false);
      setValue(val);
    },
    [setValue]
  );

  const handleChange = (val: string) => {
    validations
      .validate(val)
      .then((res: unknown) => {
        onValidationChange(true);
      })
      .catch((err: unknown) => {
        onValidationChange(false);
      });
  };
  return (
    <EditContainer
      onKeyDown={(e) => e.stopPropagation()}
      onClick={() => setIsEditing((curr) => !curr)}
    >
      {!isEditing ? (
        <Tooltip placement="top" title={value}>
          <div>{value}</div>
        </Tooltip>
      ) : (
        <Input
          validations={validations}
          onClick={stopPropagation}
          onBlur={handleBlur}
          autoFocus
          placeholder=""
          initialValue={value ?? ''}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
        />
      )}
    </EditContainer>
  );
};

export default EditInput;
