import {
  PageOptions,
  StatusMode,
  axiosInstance,
  violationCodesArray,
} from '@mgk-eld/utils';

import qs from 'qs';

export type Logs = {
  company_id: number;
  date?: string;
  driver_name?: string;
  vehicle_number?: string;
  driver_id?: number;
  include_rejected?: boolean;
  statuses?: StatusMode[];
  sort_by?: 'datetime' | 'driver_name' | 'vehicle_number';
};

type Cycle = {
  date: string;
  driver_id: number;
  company_id: number;
};

type Violations = {
  driver_id?: number;
  company_id?: number;
  company_ids?: number[];
  date?: string;
  types?: typeof violationCodesArray;
  consider_days?: number;
};
type LogDetails = {
  company_id: number;
  filter_date: string;
  filter_date_to?: string;
  driver_id: number;
};
type PreCheckProps = {
  filter_date: string;
  driver_id: number;
  company_id: number;
  options?: ['dot_inspections'?, 'codriver_status'?, 'states_with_cameras'?];
};

type Reassign = {
  company_id: number;
  driver_id: number;
  receiver_driver_id: number;
  logs_ids: Array<number>;
};

type EditLogProps = {
  driver_id: number;
  company_id: number;
  status?: string;
  datetime?: string;
  location_latitude?: number;
  location_longitude?: number;
  notes?: string;
};

const list = (paginationOptions: PageOptions, props: Logs) => {
  return axiosInstance.get(`logs`, {
    params: { ...paginationOptions, ...props },
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  });
};

const editLog = (id: number, props: EditLogProps) =>
  axiosInstance.put(`logs/${id}`, props);

const getCycle = (props: Cycle) => {
  return axiosInstance.get(`cycle`, {
    params: { ...props },
  });
};

const getViolations = (props: Violations) => {
  const query = props && qs.stringify(props, { arrayFormat: 'repeat' });
  return axiosInstance.get(`violations?${query}`);
};

const getDriverPreChecks = (props: PreCheckProps) => {
  const query = props && qs.stringify(props, { arrayFormat: 'repeat' });
  return axiosInstance.get(`logs/pre-check?${query}`);
};

const reassignToDriver = (props: Reassign) => {
  return axiosInstance.post(`logs/reassign`, {
    ...props,
  });
};

const logDetails = (props: LogDetails) => {
  return axiosInstance.get(`logs/daily`, {
    params: { ...props, collapse_intermediates: false },
  });
};
const logsApi = {
  list,
  editLog,
  getCycle,
  logDetails,
  getViolations,
  reassignToDriver,
  getDriverPreChecks,
};

export default logsApi;
