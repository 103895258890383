import styled from '@emotion/styled';
import { englishString, englishStringRegEx, grey } from '@mgk-eld/utils';
import IconButton from '@mui/material/IconButton';
import MiuInput, { InputProps } from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { ISchema, object, Reference } from 'yup';

export const CustomPaper = styled(Paper)`
  border: 1px solid ${grey[100]};
  border-radius: 8px;
  background: ${grey[50]};
  height: 40px;
`;

type Props = {
  placeholder: string;
  initialValue?: string;
  handleSubmit?: (val: string) => void;
  handleChange?: (val: string) => void;
  width?: string | number;
  height?: string | number;
  step?: string;
  next?: number;
  max?: number;
  validations?: ISchema<unknown> | Reference<unknown>;
  validation?: (value: string) => boolean;
  name?: string;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};

export const Input: React.FC<Props & InputProps> = ({
  Icon,
  placeholder,
  initialValue,
  handleChange,
  validations,
  validation,
  name,
  step,
  max = Infinity,
  next,
  width = '100%',
  height = '40px',
  ...rest
}) => {
  const {
    handleSubmit,
    handleChange: handleLocalChange,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      val: initialValue,
    },
    validationSchema: object({
      val: validations ?? englishString,
    }),
    onSubmit: ({ val }) => {
      if (!handleChange) return;

      handleChange(val || '');
    },
  });

  const [hasError, setHasError] = useState(false);

  return (
    <form
      style={{ width: width ? width : 'auto', height: height }}
      onSubmit={handleSubmit}
      onBlur={handleSubmit}
      autoComplete="off"
    >
      <CustomPaper
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: width,
          height: `${height} !important`,
          border:
            (touched.val && errors.val) || hasError
              ? 'solid 1px red !important'
              : 'inherit',
        }}
      >
        <MiuInput
          type="text"
          {...rest}
          data-next={`field-${next}`}
          id="val"
          error={touched.val && Boolean(errors.val)}
          name={name}
          sx={{ ml: 1, flex: 1 }}
          placeholder={placeholder}
          disableUnderline
          value={values.val ?? initialValue}
          onChange={(e) => {
            const isEngOnly = englishStringRegEx.test(e.target.value);
            validation
              ? setHasError(!validation(e.target.value))
              : setHasError(!isEngOnly);

            handleLocalChange(e);

            if (e.target.value.length >= String(max).length && next) {
              if (validation) {
                if (validation(e.target.value)) {
                  setHasError(false);
                  const nextElement = document.querySelector(
                    `[data-next=field-${(next || 0) + 1}] > input`
                  ) as HTMLInputElement;

                  if (nextElement) {
                    nextElement.focus();
                  } else if (next === 3) {
                    const moveBtn = document.querySelector(
                      '#moveBtn'
                    ) as HTMLButtonElement;

                    moveBtn.disabled = false;
                    moveBtn.focus();
                  } else {
                    setHasError(true);
                  }
                }
              }
            }
          }}
          inputProps={{ 'aria-label': placeholder, step, min: 0, max }}
        />
        {Icon && (
          <IconButton type="submit" sx={{ p: '10px' }} aria-label={placeholder}>
            <Icon />
          </IconButton>
        )}
      </CustomPaper>
    </form>
  );
};
