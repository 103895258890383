export const prodPaymentKey =
  'pk_live_51OaZeDBP0zdhh7ZnlDLR9RCHoUnf69s6pN6bnsfSH7ziB02iguli5TLKjpV0Mn5il2Tbrkrn5BNsq0WnkGIGaLVi00Mbhzs3LC';
export const devPaymentKey = 'pk_test_tsYdWwdC3hMhKXnn4OvQci4M00MbekmSVe';

export const isStage = () =>
  window.location.host.includes('stage') ||
  window.location.host.includes('localhost') ||
  window.location.host.includes('dev');

const prefix = isStage() ? 'stage.' : '';
const https = `https://${prefix}api.mgkeld.com/api/`;
const socket = `wss://${prefix}api.mgkeld.com/ws/`;
const trackingApp = `https://${prefix}tracking.mgkeld.com/#/`;

export const PAYMENT_KEY = isStage() ? devPaymentKey : prodPaymentKey;

export const baseUrl = {
  https,
  socket,
  trackingApp,
};
