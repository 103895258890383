import { useAppSelector } from '../../hooks/reduxHooks';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import {
  getSelectedCompanyId,
  getTimeZone,
} from '../../redux/companies/selectors';
import trackingsApi from '../../services/trackings';
import { Title } from '../Errors';
import GenerateLinkDialog from './GenerateLinkDialog';
import TrackingLink from './TrackingLink';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import {
  CustomButton,
  CustomPagination,
  DataTable,
  Input,
  MultiCheckBoxSelect,
  Status,
  YYYY_MM_DD_format,
} from '@mgk-eld/core';
import { getStatus, StatusMode, Trakings, usePagination } from '@mgk-eld/utils';
import { SearchIcon } from '@mgk-eld/assets';

const selectOptions = [
  'Driving',
  'On duty',
  'Sleeper',
  'Off duty',
  'Personal use',
  'Yard move',
];

export const Trackings: FC = () => {
  const [status, setStatus] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Trakings[]>([]);
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState<number | false>(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const columns: GridColDef[] = [
    {
      field: 'driver',
      headerName: 'DRIVER',
      flex: 0.8,
      headerAlign: 'left',
      sortable: false,
    },
    {
      field: 'unit',
      headerName: 'UNIT#',
      align: 'center',
      flex: 0.5,
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      flex: isDesktop ? 0.4 : 0.8,
      headerAlign: 'left',
      sortable: false,
      renderCell: (params) => {
        const status = getStatus(params.value);
        return <Status status={status} />;
      },
    },
    {
      field: 'lkl',
      headerName: 'LAST KNOWN LOCATION',
      flex: 1.7,
      headerAlign: 'left',
      sortable: false,
    },
    {
      field: 'generate_link',
      headerName: 'SHARE LOCATION',
      flex: 0.6,
      align: 'center',
      renderCell({ row }) {
        return isDesktop ? (
          <CustomButton onClick={() => setOpen(row.driver_id)}>
            Create Link
          </CustomButton>
        ) : (
          <IconButton onClick={() => setOpen(row.driver_id)}>
            <NoteAddIcon />
          </IconButton>
        );
      },
      sortable: false,
    },
    {
      field: 'link',
      headerName: 'LOCATION LINK',
      flex: isDesktop ? 1 : 0.2,
      headerAlign: 'left',
      renderCell({ row }) {
        const token = row.public_token;
        return token && <TrackingLink token={token} />;
      },
      sortable: false,
    },
  ];

  const selectedCompany = useAppSelector(getSelectedCompanyId);
  const tz = useAppSelector(getTimeZone);

  const { page, size, count, setSize, setTotal, handlePageChange } =
    usePagination();

  const requestData = useCallback(async () => {
    if (!selectedCompany) return;
    try {
      setLoading(true);
      const {
        data: { total, items },
      } = await trackingsApi.get({
        page,
        driver_name: query,
        size,
        statuses: status.map(
          (status) => status.replaceAll(' ', '_').toUpperCase() as StatusMode
        ),
        company_id: selectedCompany,
        date: dayjs.tz(new Date(), tz).format(YYYY_MM_DD_format),
        total: null,
      });
      setTotal(total);
      setData(items);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [selectedCompany, page, query, size, status, tz, setTotal]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const handleQueryChange = (val: string) => {
    setQuery(val);
    handlePageChange(null, 1);
  };
  const handleStatusChange = (arr: string[]) => {
    setStatus(arr);
    handlePageChange(null, 1);
  };

  const rows = useMemo(
    () =>
      data.map(
        (
          {
            name,
            vehicle_number,
            datetime,
            status,
            location,
            surname,
            ...rest
          },
          index
        ) => ({
          id: Date.now() + index,
          unit: vehicle_number,
          driver: `${name} ${surname}`,
          status: status,
          lkl: location,
          ...rest,
        })
      ),
    [data]
  );
  return (
    <Grid container direction="column" paddingX={2} paddingY={4}>
      {open && (
        <GenerateLinkDialog
          open={open}
          setOpen={setOpen}
          onSubmit={() => requestData()}
        />
      )}
      <Grid container direction="row" spacing={2}>
        <Grid display={{ xs: 'none', lg: 'block' }} item md={3}>
          <Title>Trackings</Title>
        </Grid>
        <Grid
          display={{ xs: 'none', lg: 'block' }}
          item
          md={6}
          textAlign="center"
        >
          <Input
            handleSubmit={handleQueryChange}
            placeholder="Search by name"
            Icon={SearchIcon}
          />
        </Grid>
        <Grid
          item
          md={3}
          textAlign="end"
          display="flex"
          justifyContent="flex-end"
        >
          <MultiCheckBoxSelect
            setState={handleStatusChange}
            state={status}
            options={selectOptions}
          />
        </Grid>
      </Grid>
      <Grid container mt={'10px'}>
        <DataTable
          isLoading={loading}
          rows={rows}
          autoHeight
          columns={columns}
          checkboxSelection={false}
          columnVisibilityModel={{
            lkl: isDesktop,
          }}
        />
      </Grid>
      <CustomPagination
        count={count}
        handlePageChange={handlePageChange}
        size={size}
        setSize={setSize}
        page={page}
        withSizeOptinos={false}
      />
    </Grid>
  );
};
