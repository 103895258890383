import { CustomPagination, DataTable, Status } from '@mgk-eld/core';
import { useAppSelector } from '../../hooks/reduxHooks';
import { getSelectedCompanyId } from '../../redux/companies/selectors';
import errorsApi, { ErrorUrls } from '../../services/errors';
import { extendedModes } from '../Logs/components/LogEvents';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getStatus,
  UndefinedDriverProfile,
  usePagination,
} from '@mgk-eld/utils';
import { DownloadIcon } from '@mgk-eld/assets';

const udpsColums: GridColDef[] = [
  {
    field: 'driver',
    headerName: 'ORIGIN DRIVER',
    flex: 0.7,
    headerAlign: 'left',
    sortable: false,
  },
  {
    field: 'time',
    headerName: 'TIME',
    flex: 0.7,
    headerAlign: 'left',
    sortable: false,
  },
  {
    field: 'vehicle',
    headerName: 'VEHICLE',
    flex: 0.5,
    headerAlign: 'left',
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'STATUS',
    flex: 0.7,
    headerAlign: 'left',
    sortable: false,
    renderCell: (params) => {
      if (extendedModes.includes(params.value)) {
        const statusNote = params.row.status_note;
        return (
          <Status
            statusNote={params.value === extendedModes[5] ? statusNote : ''}
            extended={{
              mode: params.value,
              color: params.value === extendedModes[0] ? '#05AB83' : '#f3e5dc',
            }}
          />
        );
      }

      const status = getStatus(params.value);
      return <Status status={status} />;
    },
  },
  {
    field: 'location',
    headerName: 'LOCATION',
    align: 'center',
    flex: 1.3,
    headerAlign: 'left',
    sortable: false,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <div className="MuiDataGrid-cellContent">{params.value}</div>
      </Tooltip>
    ),
  },
  {
    field: 'odometer',
    headerName: 'ODOMETER',
    flex: 0.5,
    headerAlign: 'left',
    sortable: false,
  },
  {
    field: 'engineHours',
    headerName: 'ENGINE HOURS',
    flex: 0.6,
    headerAlign: 'left',
    sortable: false,
  },
  {
    field: 'rejected',
    headerName: 'REJECTED BY DRIVER',
    flex: 0.5,
    headerAlign: 'left',
    sortable: false,
  },
];

const Udps: React.FC<{
  date: string;
}> = ({ date }) => {
  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [udps, setUdps] = useState<UndefinedDriverProfile[]>([]);

  const { page, size, count, setSize, setTotal, handlePageChange } =
    usePagination();

  const selectedCompany = useAppSelector(getSelectedCompanyId);

  const loadData = useCallback(async () => {
    if (!selectedCompany) return;
    setLoading(true);
    const { data } = await errorsApi.load(
      ErrorUrls.UndefinedDriverProfile,
      { page, size },
      { company_id: selectedCompany, date, include_rejected: true }
    );
    setLoading(false);
    setUdps(data.items);
    setTotal(data.total);
  }, [date, page, selectedCompany, setTotal, size]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const rows = useMemo(
    () =>
      udps.map((udp) => ({
        id: udp.id,
        driver: `${udp.driver_name} ${udp.driver_surname}`,
        time: dayjs(udp.datetime).format('MMM DD, hh:mm:ss A'),
        vehicle: udp.vehicle_number,
        status: udp.status,
        location: udp.location,
        odometer: udp.odometer_value,
        engineHours: Math.round(udp.engine_hours * 100) / 100,
        rejected: udp.rejected ? 'YES' : 'NO',
      })),
    [udps]
  );

  const handleExcelDownload = async () => {
    if (!selectedCompany) return;
    setDownloading(true);
    await errorsApi.downloadXLSX(selectedCompany, date);
    setDownloading(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          position: 'absolute',
          right: 35,
          mt: -11,
        }}
        onClick={handleExcelDownload}
        endIcon={
          downloading ? <CircularProgress size={20} /> : <DownloadIcon />
        }
      >
        Download
      </Button>
      <DataTable
        loading={loading}
        rows={rows}
        autoHeight
        columns={udpsColums}
        checkboxSelection={false}
      />
      {count > 0 && (
        <CustomPagination
          count={count}
          handlePageChange={handlePageChange}
          size={size}
          setSize={setSize}
          page={page}
        />
      )}
    </>
  );
};

export default Udps;
