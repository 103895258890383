import { YYYY_MM_DD_format } from '@mgk-eld/core';
import { Row } from '.';
import { extendedModes } from '../LogEvents';
import { ExtendedStatusMode } from '../types';
import dayjs from 'dayjs';
import { mapValues } from 'lodash';
import { statuses, StatusMode } from '@mgk-eld/utils';

export const isInStatusMode = (x: any): x is StatusMode =>
  [extendedModes[0], ...statuses].includes(x);

export const getStatusMode = (status: ExtendedStatusMode): StatusMode => {
  if (status && status === extendedModes[0]) {
    return 'DRIVING';
  }

  return status as StatusMode;
};

export const getDTs = (defaultDT: Date, draftDT?: string) => {
  return draftDT ? new Date(draftDT) : defaultDT;
};

export const getDateFromSeconds = (sec: number, date?: string) => {
  return dayjs(dayjs(date).format(YYYY_MM_DD_format)).add(sec, 'seconds');
};

export type Editable = {
  disabled: boolean;
  status: boolean;
  start: boolean;
  end: boolean;
  location: boolean;
  vehicle: boolean;
  odometer: boolean;
  engHour: boolean;
  notes: boolean;
  doc: boolean;
};

const initialValue = {
  disabled: false,
  status: true,
  start: true,
  end: true,
  location: true,
  vehicle: false,
  odometer: true,
  engHour: true,
  notes: true,
  doc: true,
};

export const isEditable = (
  rows: Row[],
  date: string,
  id: number,
  endDate?: string
) => {
  const index = rows.findIndex((row) => row.id === id);
  if (index < 0) return;
  const currentDate = dayjs().tz();

  const isPast = dayjs(rows[index].start).isBefore(date, 'date');
  const isFuture = dayjs(rows[index].start).isAfter(endDate || date, 'date');
  const status = rows[index].status;
  const isCurrent =
    (dayjs(rows[index].start).isSame(endDate || date, 'date') ||
      dayjs(rows[index].start).isBefore(currentDate, 'date')) &&
    index + 1 === rows.length;

  let editing: Editable = { ...initialValue };

  // future and past logs are not editable (future and past according to the selected date)
  if (isFuture || isPast || extendedModes.includes(status)) {
    editing.disabled = true;
  }

  // disable editing if the current status is driving
  if (isCurrent && status === statuses[0]) {
    editing = mapValues(editing, () => false);
    editing.start = true;
  }

  if (status === extendedModes[3] || status === extendedModes[4]) {
    editing.engHour = false;
    editing.odometer = false;
    editing.location = false;
  }

  return editing;
};
