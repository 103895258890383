import { green, grey, orange, purple, red, yellow } from '../theme';
import { DrivingStatusModeKey, StatusMode, StatusType } from '../types';

export const statuses = [
  'DRIVING',
  'ON_DUTY',
  'SLEEPER',
  'OFF_DUTY',
  'PERSONAL_USE',
  'YARD_MOVE',
  'CYCLE',
  'DRIVING_WAYPOINTS',
] as const;

export const statusOptions = [
  'OFF_DUTY',
  'SLEEPER',
  'DRIVING',
  'DRIVING_WAYPOINTS',
  'ON_DUTY',
  'ENGINE_POWER_UP',
  'ENGINE_SHUT_DOWN',
  'LOGIN',
  'LOGOUT',
  'PERSONAL_USE',
  'YARD_MOVE',
  'INTERMEDIATE',
  'CERTIFICATION',
] as const;

export const driverStatusDefinitions: {
  [k in DrivingStatusModeKey]: StatusType;
} = {
  driving: { color: green, mode: 'DRIVING', short: 'DR' },
  drivingWaypoints: { color: green, mode: 'DRIVING_WAYPOINTS', short: 'DWP' },
  onDuty: { color: yellow, mode: 'ON_DUTY', short: 'OND' },
  sleeper: { color: grey[300], mode: 'SLEEPER', short: 'SL' },
  offDuty: { color: grey[1000], mode: 'OFF_DUTY', short: 'OFFD' },
  personalUse: { color: purple, mode: 'PERSONAL_USE', short: 'PERS' },
  yardMove: { color: orange, mode: 'YARD_MOVE', short: 'YM' },
  cycle: { color: red, mode: 'CYCLE', short: 'CYCLE' },
};
export const extendedModes = [
  'INTERMEDIATE',
  'ENGINE_POWER_UP',
  'ENGINE_SHUT_DOWN',
  'LOGIN',
  'LOGOUT',
  'CERTIFICATION',
];

export type ExtendedStatusMode =
  | StatusMode
  | 'INTERMEDIATE'
  | 'ENGINE_POWER_UP'
  | 'ENGINE_SHUT_DOWN'
  | 'LOGIN'
  | 'LOGOUT'
  | 'CERTIFICATION';

export type Totals = {
  [T in StatusMode]: number;
};

export const logCheckErrors = {
  location_check: 'Location must change from the previous.',
  location_match: 'Location must remain the same as the previous.',
  odometer_check: 'Odometer must be greater than the previous.',
  odometer_match: 'Odometer must remain the same as the previous.',
  engine_hours: 'Engine hours must be greater than the previous.',
  empty_check: 'Empty location/odometer/engine hours fields.',
  consequent_logins: 'Two consequent Login statuses.',
  intermediate_freq:
    'The Intermediate event must be at exactly +1 hour from the previous event.',
};

export const saveLogeCheckErrors = {
  ...logCheckErrors,
  location_match:
    'Location value match - “Teleport occured. Location must remain the same as the previous.”',
  odometer_match:
    'Odometer value match - “Teleport occured. Odometer must remain the same as the previous.”',
};
