import { CustomPagination, DataTable } from '@mgk-eld/core';
import { useAppSelector } from '../../hooks/reduxHooks';
import { getSelectedCompanyId } from '../../redux/companies/selectors';
import errorsApi, { ErrorUrls } from '../../services/errors';
import {
  ViolationCode,
  Violations as ViolationsType,
  usePagination,
} from '@mgk-eld/utils';
import DeleteButton from './DeleteButton';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';

const Violations: React.FC<{ driverName: string; date: string }> = ({
  driverName,
  date,
}) => {
  const selectedCompany = useAppSelector(getSelectedCompanyId);

  const [violations, setViolations] = useState<ViolationsType[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);
  const { page, size, count, setSize, setTotal, handlePageChange } =
    usePagination();
  const loadData = useCallback(async () => {
    if (!selectedCompany) return;
    setLoading(true);
    const { data } = await errorsApi.load(
      ErrorUrls.Violations,
      { page, size },
      { company_id: selectedCompany, date, driver_name: driverName }
    );
    setLoading(false);
    setViolations(data.items);
    setTotal(data.total);
  }, [date, driverName, page, selectedCompany, setTotal, size]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleDelete = useCallback(
    async (selectedId?: number) => {
      const idListToDelete = selectedId ? [selectedId] : selectedIds;
      if (!selectedCompany) return;
      const idList = idListToDelete.map((id) => {
        const consolidatedId = id as string;
        const [datetime, code, driver_id] = consolidatedId.split('/');
        return {
          company_id: selectedCompany,
          datetime,
          code: Number(code),
          driver_id: Number(driver_id),
        };
      });
      await errorsApi.hideViolations(idList);
      loadData();
    },
    [loadData, selectedCompany, selectedIds]
  );

  const cols: GridColDef[] = useMemo(
    () => [
      {
        field: 'driver',
        headerName: 'DRIVER',
        flex: 1,
        headerAlign: 'left',
        sortable: false,
      },
      {
        field: 'vehicle',
        headerName: 'VEHICLE',
        flex: 0.5,
        sortable: false,
      },
      {
        field: 'errorType',
        headerName: 'ERROR TYPE',
        flex: 0.5,
        sortable: false,
      },
      {
        field: 'startAddress',
        headerName: 'START ADDRESS',
        flex: 1.5,
        headerAlign: 'left',
        sortable: false,
      },
      {
        field: 'odometer',
        headerName: 'ODOMETER',
        type: 'number',
        align: 'left',
        flex: 0.5,
        headerAlign: 'center',
        sortable: false,
      },
      {
        field: 'engineHours',
        headerName: 'ENGINE HOURS',
        type: 'number',
        align: 'left',
        flex: 0.7,
        headerAlign: 'center',
        sortable: false,
      },
      {
        field: 'delete',
        align: 'center',
        flex: 0.3,
        headerAlign: 'center',
        sortable: false,

        renderHeader: () => (
          <DeleteButton
            isSelected={selectedIds.length > 0}
            text="all selected Violations"
            onDelete={handleDelete}
          />
        ),

        renderCell: (props) => (
          <DeleteButton
            id={props.row.id}
            text="this Violation"
            onDelete={handleDelete}
            isSelected={selectedIds.includes(props.row.id)}
          />
        ),
      },
    ],
    [handleDelete, selectedIds]
  );
  const rows = useMemo(
    () =>
      violations.map((violation) => ({
        id: `${violation.datetime_utc}/${violation.code}/${violation.driver_id}`,
        driver: `${violation.driver_name} ${violation.driver_surname}`,
        vehicle: violation.vehicle_number,
        errorType: ViolationCode[violation.code],
        startAddress: violation.location,
        odometer: violation.odometer_value,
        engineHours: violation.engine_hours,
      })),
    [violations]
  );
  return (
    <>
      <DataTable
        loading={loading}
        columns={cols}
        autoHeight
        rows={rows}
        onRowSelectionModelChange={(ids) => setSelectedIds(ids)}
        rowSelectionModel={selectedIds}
        disableRowSelectionOnClick
      />
      {count > 0 && (
        <CustomPagination
          count={count}
          handlePageChange={handlePageChange}
          size={size}
          setSize={setSize}
          page={page}
        />
      )}
    </>
  );
};

export default Violations;
