import {
  CustomButton,
  CustomPagination,
  DataTable,
  Status,
} from '@mgk-eld/core';
import { useAppSelector } from '../../hooks/reduxHooks';
import { getSelectedCompanyId } from '../../redux/companies/selectors';
import errorsApi, { ErrorUrls } from '../../services/errors';
import {
  DelayedEvents as DelayedEventsType,
  getStatus,
  usePagination,
} from '@mgk-eld/utils';
import { extendedModes } from '../Logs/components/LogEvents';
import DeleteButton from './DeleteButton';
import { Tooltip } from '@mui/material';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

const DelayedEvents: React.FC<{ driverName: string; date: string }> = ({
  driverName,
  date,
}) => {
  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);
  const [loading, setLoading] = useState(false);
  const [delayedEvents, setDelayedEvents] = useState<DelayedEventsType[]>([]);
  const { page, size, count, setSize, setTotal, handlePageChange } =
    usePagination();
  const selectedCompany = useAppSelector(getSelectedCompanyId);

  const loadData = useCallback(async () => {
    if (!selectedCompany) return;
    setLoading(true);
    const { data } = await errorsApi.load(
      ErrorUrls.DelayedEvents,
      {
        page,
        size,
        total: null,
      },
      { company_id: selectedCompany, date, driver_name: driverName }
    );
    setLoading(false);
    setDelayedEvents(data.items);
    setTotal(data.total);
  }, [date, driverName, page, selectedCompany, setTotal, size]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleAssignEvent = useCallback(
    async (id: number | string) => {
      try {
        await errorsApi.assignEvent(id);
        toast.success('Event assigned');
        loadData();
      } catch (error) {
        console.error(error);
      }
    },
    [loadData]
  );

  const handleDelete = useCallback(
    async (selectedId?: number) => {
      const idListToDelete = selectedId ? [selectedId] : selectedIds;
      if (!selectedCompany) return;
      await errorsApi.deleteErrors(ErrorUrls.DelayedEvents, idListToDelete);
      loadData();
    },
    [loadData, selectedCompany, selectedIds]
  );

  const cols: GridColDef[] = useMemo(
    () => [
      {
        field: 'driver',
        headerName: 'DRIVER',
        flex: 0.7,
        headerAlign: 'left',
        sortable: false,
      },
      {
        field: 'time',
        headerName: 'TIME',
        flex: 0.7,
        headerAlign: 'left',
        sortable: false,
      },
      {
        field: 'vehicle',
        headerName: 'VEHICLE',
        flex: 0.5,
        headerAlign: 'left',
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'STATUS',
        flex: 0.5,
        headerAlign: 'left',
        sortable: false,
      },
      {
        field: 'event',
        headerName: 'EVENT',
        flex: 0.6,
        headerAlign: 'left',
        sortable: false,
        renderCell: (params) => {
          if (extendedModes.includes(params.value)) {
            return (
              <Status
                statusNote={params.row.status_note}
                extended={{
                  mode: params.value,
                  color:
                    params.value === extendedModes[0] ? '#05AB83' : '#E6EFF7',
                }}
              />
            );
          }
          const status = getStatus(params.value);
          return <Status status={status} />;
        },
      },
      {
        field: 'startAddress',
        headerName: 'LOCATION',
        align: 'center',
        flex: 0.5,
        headerAlign: 'left',
        sortable: false,
        renderCell: (params) => (
          <Tooltip title={params.value}>
            <div className="MuiDataGrid-cellContent">{params.value}</div>
          </Tooltip>
        ),
      },
      {
        field: 'odometer',
        headerName: 'ODOMETER',
        flex: 0.5,
        headerAlign: 'left',
        sortable: false,
      },
      {
        field: 'engineHours',
        headerName: 'ENGINE HOURS',
        flex: 0.6,
        headerAlign: 'left',
        sortable: false,
      },
      {
        field: 'notes',
        headerName: 'NOTES',
        flex: 0.5,
        headerAlign: 'left',
        sortable: false,
      },
      {
        field: 'assignEvent',
        align: 'center',
        headerAlign: 'center',
        flex: 0.7,
        sortable: false,
        renderHeader: () => null,
        renderCell: (props) => (
          <CustomButton
            sx={{ width: '117px !important' }}
            onClick={() => handleAssignEvent(props.id)}
          >
            Assign Event
          </CustomButton>
        ),
      },
      {
        field: 'delete',
        align: 'center',
        headerAlign: 'center',
        flex: 0.2,
        sortable: false,
        renderHeader: () => (
          <DeleteButton
            isSelected={selectedIds.length > 0}
            text="all selected Delayed events"
            onDelete={handleDelete}
          />
        ),

        renderCell: (props) => (
          <DeleteButton
            id={props.row.id}
            text="this Delayed event"
            onDelete={handleDelete}
            isSelected={selectedIds.includes(props.row.id)}
          />
        ),
      },
    ],
    [handleAssignEvent, handleDelete, selectedIds]
  );

  const rows = useMemo(
    () =>
      delayedEvents.map((delayedEvent) => ({
        id: delayedEvent.id,
        driver: `${delayedEvent.driver_name} ${delayedEvent.driver_surname}`,
        time: dayjs(delayedEvent.datetime).format('MMM DD, hh:mm:ss A'),
        vehicle: delayedEvent.vehicle_number,
        event: delayedEvent.status,
        status: 'Active',
        startAddress: delayedEvent.location,
        odometer: delayedEvent.odometer_value,
        engineHours: delayedEvent.engine_hours,
        notes: delayedEvent.notes,
      })),
    [delayedEvents]
  );
  return (
    <>
      <DataTable
        loading={loading}
        rows={rows}
        autoHeight
        columns={cols}
        onRowSelectionModelChange={(ids) => setSelectedIds(ids)}
        rowSelectionModel={selectedIds}
        disableRowSelectionOnClick
      />
      {count > 0 && (
        <CustomPagination
          count={count}
          handlePageChange={handlePageChange}
          size={size}
          setSize={setSize}
          page={page}
        />
      )}
    </>
  );
};

export default DelayedEvents;
