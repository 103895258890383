import { isNull } from 'lodash';
import { bool, boolean, number, object, string } from 'yup';
import dayjs from 'dayjs';
import {
  englishString,
  noCommaRegex,
  englishStringRegEx,
  isRequired,
  locationRegex,
  macAddressRegex,
  urlRegex,
  passwordString,
  IpRegex,
  hexColorRegex,
} from './common';

export const loginValidationSchema = object().shape({
  username: englishString.max(255).required('Username' + isRequired),
  password: englishString.max(255).required('Password' + isRequired),
  agree: bool().oneOf([true], 'Field must be checked'),
});

export const securityValidationSchema = object({
  name: string().required('Name is required'),
  address: string()
    .matches(IpRegex, 'Invalid IP address format')
    .required('Address is required'),
});

export const emdUserValidationSchema = object().shape({
  name: englishString.required('First Name' + isRequired),
  surname: englishString.required('Last Name' + isRequired),
  email: englishString.email().required('Email' + isRequired),
});

export const vehicleValidationSchema = object({
  unit_number: englishString
    .matches(noCommaRegex, 'No commas accepted')
    .min(1, 'Unit length has to be 1-10 characters long')
    .max(10, 'Unit length has to be 1-10 characters long')
    .required('Unit' + isRequired),
  make: englishString.required('Make' + isRequired),
  model: englishString.required('Model' + isRequired),
  vin: string()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/,
      'English letters or numbers only'
    )
    .length(17, 'Length must be 17 characters')
    .required('VIN' + isRequired),
  plate: englishString.required('Plate#' + isRequired),
  license_plate_state: string().required(
    'License State Plate a is required field'
  ),
  fuel_type: string().required('Fuel Type' + isRequired),
  odometer_offset: number().required('Odometer Offset' + isRequired),
  odometer_offset_date_from: string().nullable(),
  odometer_offset_date_to: string()
    .nullable()
    .test(
      'smaller-from-date',
      'End date should be greater than start date',
      (value, context) => {
        const from = context.parent?.['odometer_offset_date_from'];
        const to = context.parent?.['odometer_offset_date_to'];
        if (isNull(from) || isNull(to)) return true;
        return dayjs(to).isAfter(dayjs(from));
      }
    ),
});

export const driverValidationSchema = object({
  name: englishString
    .matches(noCommaRegex, 'No commas accepted')
    .required('First Name' + isRequired)
    .min(2)
    .max(30),
  surname: englishString
    .required('Last Name' + isRequired)
    .matches(noCommaRegex, 'No commas accepted')
    .min(2)
    .max(30),
  id_number: englishString.optional(),
  dl_number: englishString
    .required('Driver License Number' + isRequired)
    .matches(noCommaRegex, 'No commas accepted'),
  license_state: string().required('Driver license state' + isRequired),
  tg_link: string().matches(urlRegex, 'Provide a valid url address'),
  phone: string().matches(/^\d+$/, 'Numeric only').optional(),
  email: englishString
    .email('Email address must be a valid email')
    .required('Email address' + isRequired),
  username: englishString
    .required('Username' + isRequired)
    .min(4, 'Username must be 4-60 characters long')
    .max(60, 'Username must be 4-60 characters long')
    .matches(noCommaRegex, 'No commas accepted'),
  password: passwordString.required('Password' + isRequired),
});

export const fleetManagerValidationSchema = object({
  name: englishString
    .required('Name' + isRequired)
    .matches(noCommaRegex, 'No commas accepted')
    .min(2)
    .max(30),
  surname: englishString
    .required('Surname' + isRequired)
    .matches(noCommaRegex, 'No commas accepted')
    .min(2)
    .max(30),
  phone: string().matches(/^\d+$/, 'Numeric only').optional(),
  email: englishString
    .email('Email address must be a valid email')
    .required('Email address' + isRequired),
  username: englishString
    .required('Username' + isRequired)
    .min(4, 'Username must be 4-60 characters long')
    .max(60, 'Username must be 4-60 characters long')
    .matches(noCommaRegex, 'No commas accepted'),
  password: passwordString
    .required('Password' + isRequired)
    .test(
      'password-not-username',
      'Password cannot be the same as the username',
      function (value) {
        const { username } = this.parent; // this.parent gives access to other form fields
        return value !== username;
      }
    ),
});

export const accountUserValidationSchema = object({
  name: englishString
    .required('Name' + isRequired)
    .matches(noCommaRegex, 'No commas accepted')
    .min(2)
    .max(30),
  surname: englishString
    .required('Surname' + isRequired)
    .matches(noCommaRegex, 'No commas accepted')
    .min(2)
    .max(30),
  phone: string().matches(/^\d+$/, 'Numeric only').optional(),
  role_id: number(),
  email: englishString
    .email('Email address must be a valid email')
    .required('Email address' + isRequired),
  username: englishString.required('Username' + isRequired),
  password: passwordString
    .required('Password' + isRequired)
    .test(
      'password-not-username',
      'Password cannot be the same as the username',
      function (value) {
        const { username } = this.parent; // this.parent gives access to other form fields
        return value !== username;
      }
    ),
});
export const profileValidationSchema = object({
  phone: string().matches(/^\d+$/, 'Numeric only').optional(),
});

export const resellerValidationSchema = object({
  name: englishString.required('Reseller Brand name' + isRequired),
  domain: string().required('Whitelabel domain' + isRequired),
  eld_registration_id: englishString.required(
    'ELD registration ID' + isRequired
  ),
  eld_identifier_pt30: englishString.required(
    'ELD identifier PT30' + isRequired
  ),
  fmcsa_email: englishString.required('FMCSA submition email' + isRequired),
  smtp_server: englishString,
  smtp_login: englishString.required('Your mail server username' + isRequired),
  smtp_password: string().required(
    'Your mail server password or API key' + isRequired
  ),
  terms_and_conditions: string().required(
    'Terms and Conditions link' + isRequired
  ),
  privacy_policy: string().required('Privacy Policy link' + isRequired),
  // logo: '',
  // favicon: '',
  // eld_public_cert: '',
  // eld_cert: '',
  // eld_private_key: '',
  theme_config: object({
    primary: englishString
      .required('Primary color' + isRequired)
      .matches(hexColorRegex, 'color must be in hex code'),
    secondary: englishString
      .required('Secondary color' + isRequired)
      .matches(hexColorRegex, 'color must be in hex code'),
    success: englishString.matches(hexColorRegex, 'color must be in hex code'),
    info: englishString.matches(hexColorRegex, 'color must be in hex code'),
    warning: englishString.matches(hexColorRegex, 'color must be in hex code'),
    error: englishString.matches(hexColorRegex, 'color must be in hex code'),
  }),
});

export const accountsValidationSchema = object({
  name: englishString.required('Account Company name' + isRequired),
  phone: string()
    .matches(/^\d+$/, 'Numeric only')
    .required('Phone number' + isRequired),
  email: englishString
    .email('Email address must be a valid email')
    .required('Email address' + isRequired),
  address: englishString.required('Address line' + isRequired),
  city: englishString.required('City' + isRequired),
  state: englishString.required('State' + isRequired),
  postal_code: string()
    .matches(/^\d+$/, 'Numeric only')
    .required('Postal Code' + isRequired),
  account_name: englishString
    .required('Acount admin name' + isRequired)
    .min(2)
    .max(30),
  account_surname: englishString
    .required('Acount admin surname' + isRequired)
    .min(2)
    .max(30),
  account_username: englishString.required('Acount username' + isRequired),
  account_password: passwordString
    .required('Admin password' + isRequired)
    .test(
      'password-not-username',
      'Password cannot be the same as the username',
      function (value) {
        const { account_username } = this.parent; // this.parent gives access to other form fields
        return value !== account_username;
      }
    ),
  emd_account_name: englishString.required(
    'Account admin full name' + isRequired
  ),
  emd_account_username: englishString.required('Username' + isRequired),
  reseller_id: number().required('reseller id' + isRequired),
  emd_account_password: passwordString
    .required('Password' + isRequired)
    .test(
      'password-not-username',
      'Password cannot be the same as the username',
      function (value) {
        const { emd_account_username } = this.parent; // this.parent gives access to other form fields
        return value !== emd_account_username;
      }
    ),
});

export const companyValidationSchema = object({
  name: englishString
    .required('Company name' + isRequired)
    .matches(noCommaRegex, 'No commas accepted')
    .min(4, 'Company name must be 4-100 characters long')
    .max(100, 'Company name must be 4-100 characters long'),
  phone: string().matches(/^\d+$/, 'Numeric only').optional(),
  email: string()
    .email('Email address must be a valid email')
    .matches(/^[A-Za-z0-9\s!@#$%^&*().,_+-]+$/, 'English letters only')
    .required('Email address' + isRequired),
  call_center: string().matches(/^\d+$/, 'Numeric only').optional(),
  billing_address: englishString.optional(),
  home_terminal_state: string().required('Home terminal state' + isRequired),
  home_terminal_postal_code: string()
    .matches(/^\d+$/, 'Numeric only')
    .required('Home terminal postal code' + isRequired),
  home_terminal_address: englishString.required(
    'Home terminal address' + isRequired
  ),
  home_terminal_city: englishString.required('Home terminal city' + isRequired),
  timezone: string().required('Timezone' + isRequired),
  dot_number: englishString
    .min(1, 'DOT number length has to be 1-9 characters long')
    .max(9, 'DOT number length has to be 1-9 characters long')
    .required('DOT number' + isRequired),
  mc_number: englishString.required('MC number' + isRequired),
  main_office_state: string().required('Main office state' + isRequired),
  main_office_city: englishString.required('Main office city' + isRequired),
  main_office_postal_code: string()
    .matches(/^\d+$/, 'Numeric only')
    .required('Main office postal code' + isRequired),
  main_office_address: englishString.required(
    'Main office address' + isRequired
  ),
  company_owner_name: englishString
    .required('Company owner name' + isRequired)
    .min(2)
    .max(30),
  company_owner_surname: englishString
    .required('Company owner surname' + isRequired)
    .min(2)
    .max(30),
  company_owner_username: englishString
    .required('Username' + isRequired)
    .min(4, 'Username must be 4-60 characters long')
    .max(60, 'Username must be 4-60 characters long'),
  company_owner_password: passwordString
    .required('Password' + isRequired)
    .test(
      'password-not-username',
      'Password cannot be the same as the username',
      function (value) {
        const { company_owner_username } = this.parent; // this.parent gives access to other form fields
        return value !== company_owner_username;
      }
    ),
  allow_driver_edit_logs: bool(),
  allow_web_app_edit_logs: bool(),
  online_payment: bool().required('Required'),

  trusted: bool().test(
    'check-if-online-payment-is-enabled',
    'Online Payment should be checked to enable this field',
    (value, ctx) => {
      const onlinePayment = ctx.parent?.['online_payment'];
      return !(value && !onlinePayment);
    }
  ),
});

export const eldValidationSchema = object({
  id: englishString.required('ELD ID' + isRequired),
  type: englishString.required('ELD type' + isRequired),
  mac_address: string().matches(macAddressRegex, 'Provide a valid MAC address'),
});

export const vehicleTripReportValidationSchema = object({
  vehicle_id: englishString.required('Vehicle' + isRequired),
});

export const dvirReportValidationSchema = object({
  vehicle_id: englishString.required('Vehicle' + isRequired),
});

export const vehicleMovementReportValidationSchema = object({
  vehicle_id: englishString.required('Vehicle' + isRequired),
});

export const fmcsaReportValidationSchema = object({
  output_file_comment: englishString,
  driver_id: englishString.required('Driver' + isRequired),
  type: englishString.required('Type' + isRequired),
});

export const idlingReportValidationSchema = object({
  vehicle_id: englishString.required('Vehicle' + isRequired),
});

export const iftaReportValidationSchema = object({
  vehicle_id: englishString.required('Vehicle' + isRequired),
  state: englishString.required('State' + isRequired),
});

export const extendedIftaReportValidationSchema = object({
  vehicle_id: englishString.required('Vehicle' + isRequired),
});
export const logsReportValidationSchema = object({
  driver_id: englishString.required('Driver' + isRequired),
});

export const sendLogsReportValidationSchema = object({
  driver_id: englishString.required('Driver' + isRequired),
  type: englishString.required('Type' + isRequired),
  email: englishString
    .email('Email address must be a valid email')
    .required('Email' + isRequired),
});

export const purchaseSubscribtionValidationSchema = object({
  quantity: number().min(1),
  agreed: boolean(),
});

export function validateCoords(value: string | undefined) {
  if (!value) return false;
  const possibleCoords = value.split(',');
  const isValidCoords =
    possibleCoords.length === 2 &&
    possibleCoords.every((v) => !isNaN(Number(v)));
  if (isValidCoords) {
    return locationRegex.test(value); // Coordinate format
  } else return false;
}
export function validateLocationInput(value: string | undefined) {
  if (!value) return false;
  const possibleCoords = value.split(',');
  const isValidCoords =
    possibleCoords.length === 2 &&
    possibleCoords.every((v) => !isNaN(Number(v)));
  if (isValidCoords) {
    return locationRegex.test(value); // Coordinate format
  } else if (englishStringRegEx.test(value)) {
    return true; // English letters and special symbols
  } else {
    return false; // Neither coordinate nor string format
  }
}

export const DotValidationSchecma = object().shape({
  driver_id: number().required('Driver' + isRequired),
  report_date: string().required('Date of Inspection' + isRequired),
  date_from: string().required('Logs affected from' + isRequired),
  date_to: string().required('Logs affected to' + isRequired),
  location: string()
    .test('location-or-english', 'Invalid location', validateLocationInput)
    .required('Location is Required'),
});
